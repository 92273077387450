import { useState, useCallback } from "react";
import { Card, CardBody, Col, Row } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import moment from "moment";
import { Link } from "react-router-dom";
import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import TransactionExportCSV from "./exports/TransactionExportCSV";
import RejectTransactionModal from "./modals/RejectTransactionModal";
const TransactionTable = ({ transDatas, totalRecords, lazyState, setlazyState, loading, setLoading, setRefresh }) => {
    const [expandedRows, setExpandedRows] = useState(null);
    const [search, setSearch] = useState('')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 1000;

    const checkBox = (data) => {
        return data?.box[0]?.name || 'deleted box'
    }
    const checkMethod = (data) => {
        const method = data.method ?? "box";
        if (method == "manual") {
            return "Manual (" + (data?.box[0]?.name ?? '') + ")";
        } else if (method == "pickup") {
            return "Pickup (" + (data?.box[0]?.name ?? '') + ")";
        } else if (method == "bonus") {
            return "Monthly Bonus"
        } else if (method == "promotion") {
            return "Promotion Reward";
        } else {
            return "Box (" + (data?.box[0]?.name ?? '') + ")";
        }
    }
    const transactionMethod = (data) => {
        const method = data.method ?? "box";
        if (method == "manual") {
            return "Manual";
        } else if (method == "pickup") {
            return "Pickup"
        } else if (method == "promotion") {
            return "Promotion Reward";
        } else if (method == "manual") {
            return "Box";
        }
    }

    const boxFullAddress = (data) => {
        return data?.box[0]?.address || '-'
    }

    const boxAddressCoordinate = (data) => {
        if (data?.box[0]?.location.latitude) {
            return data?.box[0]?.location.latitude + ' ' + data?.box[0]?.location.longitude
        } else {
            return '-'
        }
    }


    const checkUser = (data) => {
        return data?.user[0]?.name || 'deleted user'
    }

    const checkUsername = (data) => {
        return data?.user[0]?.username || 'deleted user'
    }
    const usernameAddress = (data) => {
        if (data?.user[0]?.address) {
            return data?.user[0]?.address + ', ' + data?.user[0]?.city + ' ' + data?.user[0]?.postcode + ', ' + data?.user[0]?.country
        } else {
            return '-'
        }
    }

    const duration = (data) => {
        return moment.duration(moment(data.TA_End_Time).diff(moment(data.TA_Start_Time))).humanize()
    }

    const roundedVol = (data) => {
        return data.method=="promotion" ? '---' : simplify(data.TA_UCO_Volume)
    }

    const roundedWeight = (data) => {
        return data.method == "promotion" ? '---' : simplify(data.TA_UCO_Weight)
    }

    const transactionTotalAmount = (data) => {
        var amount = 0;
        var bonus = 0;
        if (data.totalAmount && data.totalAmount != 0) {
            amount = data.totalAmount;
        } else {
            if (data.bonus) {
                bonus = data.bonus;
            }
            amount = data.amount + bonus;
        }

        return balanceFormatter(amount || 0, data?.user[0]?.currency)
    }
    const transactionAmount = (data) => {
        return balanceFormatter(data.amount || 0, data?.user[0]?.currency)
    }
    const transactionBonus = (data) => {
        return balanceFormatter(data?.bonus || 0, data?.user[0]?.currency)
    }

    const transactionFare = (data) => {
        return balanceFormatter(data?.fare || 0, data?.user[0]?.currency)
    }

    const columns = [
        { field: 'TA_ID', header: 'Transaction ID', body: '', className: '' },
        { field: 'method', header: 'Method', body: checkMethod, className: '' },
        { field: 'user', header: 'Username', body: checkUser, className: '' },
        { field: 'TA_UCO_Volume', header: 'Volume (liter)', body: roundedVol, className: 'text-end' },
        { field: 'TA_UCO_Weight', header: 'Weight (kg)', body: roundedWeight, className: 'text-end' },
        { field: 'TA_Start_Time', header: 'Date', body: startTime, className: '' },
        { field: 'amount', header: 'Amount', body: transactionTotalAmount, className: 'text-end bold' },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setLoading(true);
        debouncedSearch(value);
    }

    const rowClassName = (rowData) => {
        if (!(rowData == null || rowData == undefined)) {
            if (!rowData?.UCO_Approved) {
                return 'redBg';
            }
        }
        //will be {'greyed': !rowData.active} but this is for demonstration
    }

    const showExtraData = (data,exDataName) => {
        const val = data?.extraData[exDataName];
        if (val === 0) {
            return '0';
        } 
        return val?? null
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <h5>Transaction ID : {data.TA_ID}</h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-5">
                        <label>Box :</label>
                        <ul>
                            <li>ID : {data.boxId}</li>
                            <li>Name : {checkBox(data)}</li>
                            <li>Address : {boxFullAddress(data)}</li>
                            <li>Coordinate : {boxAddressCoordinate(data)}</li>

                        </ul>

                        <label>User :</label>
                        <ul>
                            <li>ID :
                                <Link to={'/userlist'} state={data.TA_USER_ID}> {data.TA_USER_ID} </Link>
                            </li>
                            <li>Name : {checkUser(data)}</li>
                            <li>Username : {checkUsername(data)}</li>
                            <li>Address : {usernameAddress(data)}</li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        {/* <label>The amount stored on box :</label> */}
                        <label>UCO :</label>
                        <ul>
                            <li>Weight : {data.method == "promotion" ? '---' : `${simplify(data?.TA_UCO_Weight)} kg`}</li>
                            <li>Volume : {data.method == "promotion" ? '---' : `${simplify(data?.TA_UCO_Volume)} liter`}</li>
                        </ul>
                        <label>Reward :</label>
                        <ul>
                            <li>Amount : {transactionAmount(data)}</li>
                            <li>Bonus : {transactionBonus(data)}</li>
                            {transactionMethod(data) == "Pickup" &&
                                <li>Fare: {transactionFare(data)}</li>
                            }
                            <li><b className="f16">Total : {transactionTotalAmount(data)}</b></li>

                            <li>{data?.UCO_Approved ? "This Transaction is Approved" : "This transaction is rejected"}</li>

                            {/* Slops :
                            <li>Weight : {simplify(data.TA_Slops_Weight)} kg</li>
                            <li>Volume : {simplify(data?.TA_Slops_Volume)} liter</li> */}
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <label>Transaction Time : </label>
                        <ul>
                            <li>Start : {startTime(data)}</li>
                            <li>End : {endTime(data)}</li>
                            <li>Duration : {duration(data)}</li>
                        </ul>
                        {data?.extraData &&
                            <><label>Extra Information</label><ul>
                                {showExtraData(data, "hydrocarbon_level") &&
                                    <li>Hydrocarbon Level : {showExtraData(data, "hydrocarbon_level")}</li>}
                                {showExtraData(data, "alcohol_level") &&
                                    <li>Alcohol Level : {showExtraData(data, "alcohol_level")}</li>}
                                {showExtraData(data, "raw_max_level") &&
                                    <li>Raw Max Level : {showExtraData(data, "raw_max_level")}</li>}
                                {showExtraData(data, "raw_interface_level") &&
                                    <li>Raw Interface Level : {showExtraData(data, "raw_interface_level")}</li>}

                            </ul></>
                        }
                        {data?.UCO_Approved &&

                            <div>
                                <hr />
                                <RejectTransactionModal transaction={data} setRefresh={setRefresh} />

                            </div>
                        }


                    </div>
                </div>
            </div>
        );
    };

    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <h4 className="card-title mb-0">Transactions</h4>
                            </Col>
                            <Col md={6}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="ID / Box / User" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            {/* <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <TransactionExportCSV lazyState={lazyState} />
                                </div>
                            </Col> */}
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : transDatas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            rowClassName={rowClassName}
                            dataKey={loading ? '' : 'TA_ID'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column

                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default TransactionTable