import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useRef, useState } from 'react';
import useBox from 'hooks/useBox';
import { Button } from 'reactstrap';
import makeid from 'helpers/random';
import { INITIAL_OPENING_HOURS } from 'constants/value';
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Dropdown } from 'primereact/dropdown';
import { useLocation } from "react-router-dom";

const BoxesTable = ({ setVolume, setWeight, setSeries }) => {

    const location = useLocation();
    const propsData = location.state;

    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 10 }, (v, i) => i);
    const { chamberTemp, setChamberTemp, eBoxTemp, setEBoxTemp, setRefreshTransaction, setRefreshGraph, box, setBox, toggleRightCanvas, datas, loading, setPin, setRefresh, transaction, filterTransaction, setCapacity, setSelectedLanguage, setSelectedMode, setSelectedGroup, setSlops, setBoxImages, setOpeningHoursArray, collectionPoint, setCollectionPoint, heartbeatEnabled,setHeartbeatEnabled, setTransDatas, setTotalRecords, lazyState, setBoxIdFilter, groupOptions, groupFilter, setGroupFilter,groupStatus, setGroupStatus } = useBox();
    const [globalFilterValue, setGlobalFilterValue] = useState(propsData||'');
    const [filters, setFilters] = useState({
        global: { value: propsData||null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        address: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    let noData = { value: 'all', name: "All", color: "" };
    let defaultData = { value:'none', name: "Default", color: "" };

    const groupDropdown = [noData,defaultData, ...groupOptions];
    // Add new data to the beginning of the array
    const incrementId = (data, props) => {
        return props.rowIndex ;
    }

    const changeGroup = (e) => {
        setGroupFilter(e.value);
        refreshContent()
        setRefresh(makeid(5))
        setRefreshTransaction(makeid(5))
        setRefreshGraph(makeid(5))
    }
    const coordinate = (data) => {
        if (!data.gpsLatitude) {
            return ''
        }
        return `${data.gpsLatitude},${data.gpsLongitude}`
    }

    const fillRate = (data) => {
        if (!data.gpsLatitude) {
            return '0%'
        }
        return `${Math.round((data.lUCOHold + Number.EPSILON) * 100) / 100}%`
    }

    const slopRate = (data) => {
        if (!data.gpsLatitude) {
            return '0%'
        }
        return `${Math.round((data.lSlopsHold + Number.EPSILON) * 100) / 100}%`
    }

    const badgeStatus = (data) => {
        let badgeText = data.status;
        let badgeStatus = '';// Default badge text

        

        // Conditionally change badgeText based on status and isOpen
        if (data.status === 'active' ) {
            if (data.isOpen)
                badgeStatus = `open`;
            else
                badgeStatus = `closed`;
        }

        return <>
            <span className={`badge bg-${data.status} text-t-${data.status}`}>
                {badgeText}
            </span>
            <br />
            <span className={`badge bg-${badgeStatus == 'open' ? data.status : "offline"} text-t-${badgeStatus == 'open' ? data.status : "offline"}`}>
                {badgeStatus}
            </span>

        </>;
    }
    const getBoxNumber = (data) => {
        return data.number ?? 0;
    }
    const columns = [
        { field: 'number', header: '#', body: getBoxNumber },
        // { field: 'id', header: 'ID', body: '', style: {minWidth: '14rem'} },
        { field: 'name', header: 'Name', body: '', style: { minWidth: '8rem' } },
        // { field: 'address', header: 'Address', body: '', style: {}, className: 'field-address' },
        { field: 'group_name', header: 'Group', body: '', style: {} },
        { field: 'lUCOHold', header: 'Fill Level', body: fillRate, sortable: true, style: { minWidth: '5.5rem' } },
        { field: 'lSlopsHold', header: 'Slops Level', body: slopRate, sortable: true, style: { minWidth: '5.5rem' } },
        { field: 'status', header: 'Status', body: badgeStatus, style: {} },
        // { field: 'lSlopsHold', header: <>Yield<br />liter/d</>, body: '', sortable: true, style: { minWidth: '5.5rem' } },
 ]

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

   
    const navigateMarker = async (e) => {
        setBox(e.value);
        setBoxImages(e.value?.images || [])
        setSelectedMode(e.value.status);
        setSelectedLanguage(e.value?.language);

        setSelectedGroup(e.value.group||'none')
        setOpeningHoursArray(('openingHours' in e.value ? e.value.openingHours.length !== 0 ? e.value.openingHours : INITIAL_OPENING_HOURS : INITIAL_OPENING_HOURS))
        filterTransaction(e.value.id, transaction, setVolume, setWeight, setSeries);
        setBoxIdFilter(e.value.id)
        setRefreshTransaction(makeid(5));
        setRefreshGraph(makeid(5));
    }


    const openBoxDetail = (e) => {
        if (e.data.collectionPoint) {
            setCollectionPoint(true)
        } else {
            setCollectionPoint(false)
        }
        setHeartbeatEnabled(e.data.config?.HEARTBEAT_ENABLED ?? false);
        setSelectedMode(e.data.status)
        setSelectedLanguage(e.data?.language);

        setOpeningHoursArray(('openingHours' in e.data ? e.data.openingHours.length !== 0 ? e.data.openingHours : INITIAL_OPENING_HOURS : INITIAL_OPENING_HOURS))
        setBoxImages(e.data.images)
        setPin(true);
        setCapacity(e.data.lUCOHold);
        setSlops(e.data.lSlopsHold);
        setEBoxTemp(e.data?.eBoxTemp || 0);
        setChamberTemp(e.data?.chamberTemp || 0);
        if (e.data.id === box?.id) {
            toggleRightCanvas();
        }
    }

    const refreshContent = () => {
        setBox(null);
        setBoxImages([]);
        setPin(true);
        setGroupStatus(true);
        setRefresh(makeid(5));
        filterTransaction(null, transaction, setVolume, setWeight, setSeries)
        setSelectedMode('');
        setSelectedLanguage("id");
        setOpeningHoursArray(INITIAL_OPENING_HOURS);
        setBoxIdFilter(null);
        setRefreshTransaction(makeid(5));
        setRefreshGraph(makeid(5));

    }

    const renderHeader = () => {
        return (
            <div className="justify-content-between align-items-center" style={{ display: 'flex' }}>
                <div>
                    <div>
                        <Dropdown
                            value={groupFilter || ''}
                            onChange={changeGroup}
                            options={groupDropdown}
                            optionLabel="name"
                            className="h-1 payment-status-dropdown"
                        />
                    </div>
                </div>
                <div className='d-flex gap-2 align-items-center'>
                    <div className='d-flex gap-2 align-items-center'>
                        <button type='button'
                            className='btn-refresh btn btn-sm'
                            onClick={refreshContent} >
                            <i className="mdi mdi-reload" style={{ fontSize: '28px' }}></i>
                        </button>
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className='p-inputtext-sm box-filter' style={{ width: '170px' }} />
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const rowClassName = (rowData) => {
        if (!(rowData == null || rowData == undefined)) {
            if (rowData?.is_alert || rowData?.is_slop_alert) {
                return 'orangeBg';
            }
        }
    }

    const header = renderHeader();
    return (
        <DataTable
            removableSort
            header={header}
            filters={filters}
            rowClassName={rowClassName}
            globalFilterFields={['name', 'address','group_name']}
            value={loading ? items : datas}
            stripedRows
            showGridlines
            size={'normal'}
            metaKeySelection={true}
            selectionMode={'single'}
            selection={loading ? '' : box}
            onSelectionChange={navigateMarker}
            onRowClick={openBoxDetail}
            scrollable 
            scrollHeight='439px'
        >
            {columns.map((col, i) => (
                <Column sortable={col.sortable} key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton></Skeleton> : col.body} style={col.style} className={col.className} />
            ))}
        </DataTable>
    )
}

export default BoxesTable