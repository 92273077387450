import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Link } from "react-router-dom";
import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import BoxTransactionExportCSV from "../exports/BoxTransactionExportCSV";
const BoxTransactionHistoryModal = ({ modal_center, tog_center, data }) => {
    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);
    const delay = 300;
    const checkBox = (data) => {
        return data?.box_name || 'deleted box'
    }

    const checkUser = (data) => {
        return data?.user[0]?.name || 'deleted user'
    }
    const checkUsername = (data) => {
        return data?.user[0]?.name || 'deleted user'
    }
    const duration = (data) => {
        return moment.duration(moment(data.TA_End_Time).diff(moment(data.TA_Start_Time))).humanize()
    }
    const transactionMethod = (data) => {
        const method = data.method ?? "box";
        if (method == "manual") {
            return "Manual";
        } else if (method == "pickup") {
            return "Pickup"
        } else if (method == "manual") {
            return "Box";
        }
    }
    const roundedVol = (data) => {
        return simplify(data.TA_UCO_Volume)
    }

    const roundedWeight = (data) => {
        return simplify(data.TA_UCO_Weight)
    }

    const transactionTotalAmount = (data) => {
        var amount = 0;
        var bonus = 0;
        if (data.totalAmount && data.totalAmount != 0) {
            amount = data.totalAmount;
        } else {
            if (data.bonus) {
                bonus = data.bonus;
            }
            amount = data.amount + bonus;
        }
  
        return balanceFormatter(amount || 0, data?.user[0]?.currency)
    }
    const transactionAmount = (data) => {
        return balanceFormatter(data.amount || 0, data?.user[0]?.currency)
    }
   const transactionBonus = (data) => {
        return balanceFormatter(data?.bonus || 0, data?.user[0]?.currency)
    }

    const transactionFare = (data) => {
        return balanceFormatter(data?.fare || 0, data?.user[0]?.currency)
    }
    const columns = [
        { field: 'TA_ID', header: 'Transaction ID', body: '' },
        { field: 'user_name', header: 'User', body: checkUser },
        { field: 'TA_UCO_Volume', header: 'Volume (liter)', body: roundedVol },
        { field: 'TA_UCO_Weight', header: 'Weight (kg)', body: roundedWeight },
        { field: 'TA_Start_Time', header: 'Date', body: startTime },
        { field: 'amount', header: 'Amount', body: transactionTotalAmount },,
    ]
    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [trans, setTrans] = useState([]);
    const [search, setSearch] = useState('');
    const [expandedRows, setExpandedRows] = useState(null);

    const [lazyState, setlazyState] = useState(initialLazyState);

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const getTransactionHistory = async () => {
        try {
            setLoading(true);
            const response = await axiosApi.get(`/api/admin/engineer/box/transaction?id=${data?.id}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
            const result = response.data.result;
            setTrans(result);
            setTotalRecords(response.data.totalRecords || 0)
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }
    
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getTransactionHistory()
        };
    }, [modal_center, lazyState]);

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }
    const showExtraData = (data,exDataName) => {
        const val = data?.extraData[exDataName];
        if (val === 0) {
            return '0';
        } 
        return val?? null
    }


    const rowExpansionTemplate = (data) => {
        return (
            <div className="trans-detail">
                <h5>Transaction ID : {data.TA_ID}</h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div>                       
                        <label>Box :</label>
                        <ul>
                            <li>ID : {data.boxId}</li>
                            <li>Name : {checkBox(data)}</li>
                        </ul>

                        <label>User :</label>
                        <ul>
                            <li>ID :
                                <Link to={'/userlist'} state={data.TA_USER_ID}> {data.TA_USER_ID} </Link>
                            </li>
                            <li>Name : {checkUser(data)}</li>
                            <li>Username : {checkUsername(data)}</li>
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        {/* <label>The amount stored on box :</label> */}
                        <ul>
                            UCO :
                            <li>Weight : {simplify(data.TA_UCO_Weight)} kg</li>
                            <li>Volume : {simplify(data?.TA_UCO_Volume)} liter</li>
                        </ul>

                        <ul>
                        <label>Reward :</label>
                        <li>Amount : {transactionAmount(data)}</li>
                        <li>Bonus : {transactionBonus(data)}</li>
                        {transactionMethod(data) == "Pickup" &&
                            <li>Fare: {transactionFare(data)}</li> 
                            }
                        <li><b className="f16">Total : {transactionTotalAmount(data)}</b></li>
                        
                        <li>{data?.UCO_Approved ? "This Transaction is Approved" : "This transaction is rejected"}</li>
                   
                            {/* Slops :
                            <li>Weight : {simplify(data.TA_Slops_Weight)} kg</li>
                            <li>Volume : {simplify(data?.TA_Slops_Volume)} liter</li> */}
                        </ul>
                    </div>
                    <Divider layout="vertical" />
                    <div>
                        <label>Transaction Time : </label>
                        <ul>
                            <li>Start : {startTime(data)}</li>
                            <li>End : {endTime(data)}</li>
                            <li>Duration : {duration(data)}</li>
                     </ul>
                     {data?.extraData &&
                            <><label>Extra Information</label><ul>
                                {showExtraData(data, "hydrocarbon_level") &&
                                    <li>Hydrocarbon Level : {showExtraData(data, "hydrocarbon_level")}</li>}
                                {showExtraData(data, "alcohol_level") &&
                                    <li>Alcohol Level : {showExtraData(data, "alcohol_level")}</li>}
                                {showExtraData(data, "raw_max_level") &&
                                    <li>Raw Max Level : {showExtraData(data, "raw_max_level")}</li>}
                                {showExtraData(data, "raw_interface_level") &&
                                    <li>Raw Interface Level : {showExtraData(data, "raw_interface_level")}</li>}

                            </ul></>
                        }
                        {data?.UCO_Approved &&
                           
                            <div>
                              {/* <hr/>
                                  <RejectTransactionModal transaction={data} />
                       */}
                              </div>  
                        }
                    
                        
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
        <Modal isOpen={modal_center} toggle={tog_center} centered size={'xl'}>
            <ModalHeader className="mt-0 with-close" toggle={tog_center}>
                <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title m-0">Transaction History | {data?.name}</h4>
                    <div className="d-flex align-items-center gap-3 me-2">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText value={search} onChange={onSearchTextChange} placeholder="ID / User" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                        </span>
                        <BoxTransactionExportCSV lazyState={lazyState} data={data} />
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="min-modal-body">
                <DataTable
                    lazy
                    value={loading ? items : trans}
                    stripedRows
                    paginator
                    rows={lazyState.config.rows}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    size={'normal'}
                    onPage={onPage}
                    totalRecords={totalRecords}
                    first={lazyState.config.first}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                    scrollable
                    scrollHeight = '500px'
                    expandedRows={loading ? null : expandedRows}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={onRowToggle}
                    dataKey={loading ? '' : 'TA_ID'}
                >
                    <Column expander={true} style={{ width: '3rem' }} />
                    {columns.map((col, i) => (
                        <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                    ))}
                </DataTable>
            </ModalBody>
        </Modal>
        </>
    )
}

export default BoxTransactionHistoryModal
