import React, { useState } from "react"
import { Link } from "react-router-dom"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import BonusHistoryModal from "./modals/BonusHistoryModal";

import qrIcon from "../../assets/images/services-icon/qr-icon.png";
import { balanceFormatter } from "helpers/functions";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Modal, ModalBody, ModalHeader, Form
} from "reactstrap";

import avatar from "../../assets/images/users/user-blank.png";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useLeaderboard from "hooks/useLeaderboard";
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
// import TransactionHistoryModal from "./modals/TransactionHistoryModal";
// import PaymentHistoryModal from "./modals/PaymentHistoryModal";
import { currencyFormatter, simplify, inputDateTime, debounce } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import LeaderExportCSV from "./exports/LeaderExportCSV";

const LeaderTable = ({ loading, id, lazyState, setlazyState, startDate, endDate }) => {
    const { users, totalRecords, modal_center, setmodal_center, setBonusHistoryTitle, setBonusHistory, setBonusTotalRecords } = useLeaderboard();
    const [search, setSearch] = useState(id || '')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;
    const axiosApi = useAxiosPrivate();


    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const roundedVol = (data) => {
        return simplify(data)
    }
    const handleRowClick = async (rowData) => {
        setBonusHistoryTitle(rowData.data.name)
        const userId = rowData.data._id
        const response = await axiosApi.get(`/api/admin/support/transaction?id=${userId}&method=bonus&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}`);
        setBonusHistory(response.data.result)
        setBonusTotalRecords(response.data.totalRecords||0)
        setmodal_center(!modal_center)
    };


    const columns = [
        { field: '_id', className: "bold", header: '#', body: incrementId },
        { field: 'name', header: 'Name', body: (data) => data?.name || '-' },
        { field: 'username', header: 'Username', body: (data) => data?.username || '-' },
        { field: 'totalVolume', className: "bold", header: 'Volume (liter)', body: (data) => roundedVol(data?.totalVolume) || '-' },
        { field: 'totalWeight', className: "bold", header: 'Weight (kg)', body: (data) => roundedVol(data?.totalWeight) || '-' },
        { field: 'totalAmount', header: 'Amount (excl. Bonus)', body: (data) => balanceFormatter(data?.totalAmount, data.currency) || '-' },
        { field: 'monthlyBonus', header: 'Current Month’s Bonus', body: (data) => balanceFormatter(data?.monthlyBonus, data.currency) || '-' },
        { field: 'totalReceivedBonus', header: 'Total Bonus', body: (data) => balanceFormatter(data?.totalReceivedBonus, data.currency) || '-' },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <h4 className="card-title mb-0">Top 50 UCO Collected</h4>
                            </Col>

                            <Col md={6}>
                                <div className="float-end d-block d-flex flex-row gap-2 export-icon">
                                    <LeaderExportCSV lazyState={lazyState} startDate={startDate} endDate={endDate} />
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : users}
                            stripedRows
                            selectionMode={'single'}
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            onPage={onPage}
                            onRowClick={handleRowClick}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate=""
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}
                        >
                            <Column style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} className={col.className} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                        <BonusHistoryModal />

                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default LeaderTable
